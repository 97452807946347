
import moment from 'moment-timezone';

(function timeAgo(selectors) {

    var debugOut = false;
    var consoleLog = function(text) {
        if(debugOut) console.log(text);
    };

    // 1 minute
    timeAgo.interval = 60000;
    // 24 hours
    timeAgo.diffMin  = 60000 * 1440;

    var adjustInterval = function() {
        switch(true) {
            case (timeAgo.diffMin < 5): 
                // 60 seconds
                timeAgo.interval = 60000;
                break;

            case (timeAgo.diffMin >= 5 && timeAgo.diffMin < 30): 
                // multiply by 5 minutes
                timeAgo.interval = 60000 * 5;
                break;

            case (timeAgo.diffMin >= 30 && timeAgo.diffMin < 60): 
                timeAgo.interval = 60000 * 30;
                break;

            case (timeAgo.diffMin >= 60 && timeAgo.diffMin < 1440): 
                timeAgo.interval = 60000 * 60;
                break;

            case (timeAgo.diffMin >= 1440): 
                timeAgo.interval = 60000 * 1440;
                break;

            default:
                timeAgo.interval = 60000;
                break;
        };
        consoleLog(`timeAgo - new interval: ${timeAgo.interval}`);
    };

    var timer = function(time, future, past) {
        moment.updateLocale('en', {
            relativeTime : {
                future: future,
                past: past,
            }
        });
        return moment(time).fromNow();
    };

    var updateElements = function(elements) {
        let limit = elements.length;
        consoleLog(`timeAgo - updating ${limit} elements`);
        for(var ix = 0;ix < limit;ix++) {
            var $this = elements[ix];
            if(typeof $this === 'object') {
                let time = $this.getAttribute('datetime') || $this.getAttribute('title');
                $this.setAttribute('datetime',time);
                $this.innerHTML = timer(time, $this.getAttribute('data-future')||'for %s', $this.getAttribute('data-past')||'%s ago');
                $this.setAttribute('title',moment(time).format('MMM Do YYYY [at] h:mma'));

                let diffMin = Math.abs(moment(time).diff(moment().now, 'minutes')); 
                if(diffMin < timeAgo.diffMin) {
                    timeAgo.diffMin = diffMin;
                }
            }
        }
        adjustInterval();
        return limit;
    };

    var updateAll = function() {
        var elements = document.getElementsByClassName('timeago');
        return updateElements(elements);
    };

    var endTimer = function() {
        if(typeof timeAgo.timeoutId !== 'undefined') {
            consoleLog(`timeAgo - clearing timeout ID: ${timeAgo.timeoutId}`);
            clearTimeout(timeAgo.timeoutId);
            delete timeAgo.timeoutId;
        }
    };

    if(typeof selectors === 'undefined') {
        endTimer();
        if(updateAll() > 0) {
            // update after a set interval
            timeAgo.timeoutId = setTimeout(timeAgo, timeAgo.interval);
            consoleLog(`timeAgo - new timeout, ID: ${timeAgo.timeoutId}  interval: ${timeAgo.interval}  now: ${moment().format('YYYY-MM-DD HH:mm:ss')}`);
        } else {
            consoleLog(`timeAgo - no elements to update, timer not started`);
        }
    } else {
        updateElements(selectors);
    }

    global.timeago = timeAgo;
})();

global.moment = moment;

